.Incubation {
  position: relative;
  .projects {
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    .card {
      margin: 10px 0;
      border-radius: 20px;
      width: calc(50% - 10px);
      height: 330px;
      &.middle {
        margin: 0 2%;
      }
      cursor: pointer;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      position: relative;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .Incubation {
  }
}
