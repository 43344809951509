.StakeBox {
  border: 1px solid var(--tertiary);
  min-height: 455px;
  min-width: 300px;
  position: relative;
  background: var(--tertiary-background);
  border-radius: 10px;
  border-top-left-radius: 0;
  .Progress {
    height: 200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .Title {
    transform: translate(10px, -13px);
    background: var(--primary);
    width: fit-content;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 3px 15px;
    border-radius: 10px;
  }
  .SelectionTab {
    position: absolute;
    right: 0;
    top: -35px;
    display: flex;
    align-items: center;
    font-weight: 600;
    gap: 10px;
    justify-content: space-between;
    cursor: pointer;
    .material-icons {
      font-size: 1.2rem;
    }
    > div {
      animation: fade-in 0.3s ease;
      display: flex;
      align-items: center;
      gap: 3px;
      background: var(--tertiary-background);
      padding: 5px 15px;
      border-radius: 8px;
    }
  }
  .Content {
    transform: translateY(-11px);
    .Nfts {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fill, 195px);
      place-content: center;
      padding: 10px 0;
      .Selections {
        position: relative;

        .MuiCheckbox-root {
          padding: 0;
          width: 100%;
          height: 100%;
          transition: all 0.2s ease;
          position: absolute;
          margin: 0;
          z-index: 2;
          svg {
            position: absolute;
            right: 10px;
            top: 6px;
            color: var(--secondary);
          }
          .MuiTouchRipple-root {
            color: var(--secondary);
          }
        }

        &.Passive {
          .MuiCheckbox-root {
            max-height: 0;
            opacity: 0;
          }
        }
      }
    }
  }
}
.ModalContent {
  background: black;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 20px;
  min-width: 30vw;
  .Title {
    font-weight: 600;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .Actions {
    display: flex;
    justify-content: space-evenly;
    button {
      outline: none;
      background: transparent;
      border: 1px solid var(--tertiary);
      border-radius: 10px;
      padding: 10px;
      width: 100px;
      &:last-of-type {
        color: var(--secondary);
      }
    }
  }
}
