.IncubationProject {
  position: relative;
  .project {
    margin-top: 50px;
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    flex-direction: row;

    .carousel-container {
      width: 50%;
      display: flex;
      justify-content: center;
      .carousel-root {
        max-width: 400px;

        .slider-wrapper {
          border-radius: 3px;
        }
      }
    }

    .card-metadata {
      width: 50%;
      display: flex;
      flex-direction: column;

      .Top {
        h2 {
          margin: 0;
        }
        h4 {
          margin-top: 10px;
          text-align: end;
        }
        span {
          font-weight: bold;
        }
      }

      .TabWiew {
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 30px;

        display: flex;
        flex-direction: column;
        border: 1px solid;
        padding-bottom: 16px;

        p {
          padding: 0 10px;
          &.drop-description {
            margin: 20px 0;
          }
        }
        > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          .Tab {
            flex: 1;
            border-bottom: 1px solid;
            text-align: center;
            font-weight: bold;
            padding: 5px 0;
            cursor: pointer;
            &.Active {
              background: white;
              span {
                color: black;
              }
            }
            &.Border {
              border-left: 1px solid;
              border-right: 1px solid;
            }
          }
        }
        .buttons {
          padding: 0 10px;
          display: flex;
          justify-content: center;
          button {
            width: 150px;
            outline: none;
          }
        }

        .error-text {
          color: rgb(230, 0, 0);
        }
      }
    }
  }
  .confettiContainer {
    position: fixed !important;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.8);
    z-index: 129 !important;
    .confetti {
      z-index: 131;
    }

    .newNftContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;

      .newNft {
        height: 370px;
        border-radius: 5px;
        overflow: hidden;
        transform: scale(0);
        opacity: 0;
        transition: all 0.8s cubic-bezier(0.18, 0.89, 0.32, 1.27);

        &.newNftAnimation {
          opacity: 1;
          transform: scale(1);
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .spinner-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .MuiCircularProgress-svg {
      color: var(--secondary);
    }
  }
  .wrongNet {
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
  }
}
@media screen and (max-width: 960px) {
  .IncubationProject {
    .project {
      margin-top: 0px;
      flex-direction: column;
      .carousel-container,
      .card-metadata {
        margin-top: 50px;
        width: 100%;
      }
    }
  }
}
