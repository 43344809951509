.RarityScore {
  color: white;
  min-height: 100vh;
  overflow: hidden;
  z-index: 2;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: -1;
  }
  
  .container {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }

  .searchBox {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  input,
  button {
    width: 270px;
    height: 45px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 1.1rem;
  }
  input {
    padding: 0 15px;
  }
  button {
    margin-left: 10px;
    width: 45px;
    height: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    .Spinner {
      color: black;
    }
  }

  button:disabled,
  button[disabled] {
    color: #949494;
  }

  .rarityContent {
    margin: 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 100;

    h1 {
      font-size: 2rem;
      display: flex;
      align-items: center;
      .open-table{
        font-size: 1rem;
        background: white;
        border-radius: 100%;
        padding: 5px;
        color: var(--primary);
        float: right;
        width: 30px;
        height: 30px;
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .topContainer {
      margin-top: 70px;
      display: flex;
      align-items: center;
      gap: 30px;
      flex-wrap: wrap;
      .video {
        border-radius: 8px;
      }

      .metadata {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 20px;
        .metadataRow {
          background: rgba(0,0,0, 0.7);
          border: 1px solid var(--secondary);
          display: flex;
          flex-direction: column;
          padding: 2px 10px;
          min-width: 100px;
          border-radius: 10px;
          > span {
            &:first-of-type {
              font-weight: 600;
              &.text-left {
                text-align: left;
              }
            }
            &:last-of-type {
              font-size: 0.9rem;
            }
            word-break: break-word;
          }
        }
      }
    }

    .table {
      margin-top: 70px;
      width: 100%;

      .row {
        display: flex;
        background: rgba(0,0,0, 0.7);

        // border-bottom: 1px solid white;
        &:nth-child(2n) {
          background: var(--tertiary);
        }
        :first-child {
          text-align: start;
          padding-left: 5px;
        }

        :last-child {
          text-align: end;
          padding-right: 5px;
        }

        span {
          flex: 1;

          &.geneName {
            text-transform: uppercase;
          }
        }
        &.thead {
          font-weight: 600;
          background: rgba(0, 0, 0, 0.7);
          padding: 5px 0;
          font-size: 1.1rem;
        }
      }
      :last-child {
        border-bottom: none;
      }
    }
  }
}
.modal{
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary);
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(255,255,255,.7);
  > .title{
    font-size: 2rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .chartContainer{
    height: 240px;
    flex-basis: 100%;
  }

  .pie{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .data{
    flex-basis: 100%;
    margin-left: 20px;
    .range{
      &.header{
        font-weight: bold;
        text-decoration: underline;
      }
      display: flex;
      justify-content: space-between;
    }
  }
}
@media screen and (max-width: 1100px) {
  .RarityScore {
    h1 {
      font-size: 1.8rem;
    }
    .rarityContent {
      .topContainer {
        flex-direction: column;
        margin-top: 30px;
        width: 100% !important;
        max-width: 100% !important;
        padding: 0;

        .image {
          margin: 0;
        }
        .metadata {
          margin: 0;
          margin-top: 70px;
        }
        .table {
          width: 100%;
          margin-top: 70px;
        }
      }
    }
  }
}
