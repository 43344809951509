html {
  font-size: 14px;
}

body {
  background-color: var(--primary);
}

.App {
  text-align: center;
}

.container {
  max-width: 960px;
  // max-width: 1280px;
  width: 100%;
  // padding: 40px 0px;
  margin: auto;
}

b {
  font-size: 1.4rem;
}

p {
  font-size: 1.2rem;
  text-align: justify;
  font-weight: 300;
}

p.title {
  font-size: 3.5rem;
  font-weight: bold;
  text-align: left;
  // border-bottom: 2px solid var(--secondary);
  // display: inline-block;
  text-align: center;
}

p.placeholder {
  text-align: center;
  width: 100%;
}

.animated-button {
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.button {
  border-radius: 10px;
  // background-color: var(--secondary) !important;
  background: linear-gradient(45deg, #f5292a, #f9981f);
  margin: 2px !important;
}

.rainbow {
  position: absolute;
  width: 100%;
  height: 200%;
  animation: o-rotate-360 linear 8s infinite;
  top: -25px;
  span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(-50%, -50%);

    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 100%;
    }

    &:first-child {
      background: white;
      &:after {
        background: black;
      }
    }

    &:last-child {
      background: black;
      &:after {
        background: white;
      }
    }
  }
}

.main-overlay{
  width: 100%;
  height: 100%;
  top: 0;
  position: fixed;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 1;
}
.main-bg {
  animation: blur-animation 3s linear;
}
.loading-gif {
  z-index: 1;
  height: 70px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;

  &.loading-gif-animation{
    opacity: 0;
    transition-delay: 2s !important;
    transition: opacity 1s;
  }
}

#man,
#concept-bg,
#sky {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-position: 50% 50%;
}

#man {
  top: 0;
  top: 250px;
  left: 600px;
}

#concept-bg {
  transform: scale(1.04);
  background-position-x: 0px;
}
#sky {
  top: 0;
  z-index: -1;
  background-position-x: 0px;
  background-position-y: -50px;
}

@keyframes blur-animation {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  20%{
    opacity: 1;
  }

  100% {
    filter: blur(0);
  }
}

@keyframes o-rotate-360 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

canvas {
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 3s ease-out;
  &.false {
    opacity: 1;
  }
  &.true {
    opacity: 0;
  }
}

@media screen and (max-width: 600px) {
  .arrow-down-icon {
    display: none;
  }
  p {
    font-size: 1.1rem !important;
  }
  p.title {
    font-size: 2.5rem !important;
  }
}

@media screen and (max-width: 960px) {
  p {
    font-size: 1.3rem;
  }
  p.title {
    font-size: 3.2rem;
  }
}
