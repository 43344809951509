.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 80px;
  z-index: 124;
  max-width: 100vw;

  .logo {
    display: inline-block;
    cursor: pointer;
    margin-right: 20px;
    img {
      height: 40px;
    }
  }
  .container {
    max-width: 992px;
    padding: unset !important;

    .navbar-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      .navbar-link-button {
        background: transparent;
        padding: 0;
        font-weight: 600;
        font-size: 1.1rem;
        &:disabled {
          opacity: 0.4;
        }
      }
      &.Active {
        .navbar-link-button {
          color: var(--secondary);
        }
      }

      &.disabled-link {
        pointer-events: none;
        opacity: 0.7 !important;
      }

      cursor: pointer;
      padding: 15px;
      color: white;
      text-decoration: none;
      &.app {
        border-left: 1px solid white;
        a {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
          }
        }
      }

      .nav-line {
        margin-top: 1px;
        width: 20px;
        height: 1px;
        &.active-line {
          background: var(--secondary);
        }
      }
    }
    .navbar-start {
      padding: 10px 0;
      align-items: center;
      display: flex;
      position: relative;
    }
    a {
      color: white;
      text-decoration: none;
      background-color: transparent !important;
    }

    .social-icons {
      display: flex;
      flex-direction: row;
      padding: 0 15px;
      gap: 5px;
      .social {
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px !important;
        border-radius: 500px;
        margin-right: 5px !important;
        svg {
          font-size: 16px !important;
        }
        img {
          height: 16px;
        }
      }
    }

    .navbar-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      position: relative;

      .navbar-end {
        // padding: 0 !important;
        padding-left: 15px;
        // display: flex;
        // align-items: center;
        // gap: 30px;

        .button {
          position: relative;
          padding: 0;
          background: unset !important;
        }
      }

      .menu-icon {
        display: none;
        margin-right: 10px;
      }
      .sidebar {
        width: 80px;
        height: calc(100vh - 80px);
        position: fixed;
        left: 0px;
        top: 80px;
        // border-right: 1px solid var(--tertiary);
        display: flex;
        align-items: center;
        flex-direction: column;
        b {
          font-size: 1rem;
          text-align: center;
          width: 100%;
          padding: 20px 0px 10px;
        }
        .portfolio-project {
          position: relative;
          margin: 10px;
          width: 60px;
          height: 60px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px;
          overflow: hidden;
          transition: all 0.3s ease-out;
          img {
            position: relative;
            width: 60px;
            object-fit: center;
            background: var(--primary);
            z-index: 5;
            opacity: 0.6;
            transition: all 0.3s ease-out;
          }
          &:hover {
            box-shadow: 0px 0px 5px #9ca0b9;
            img {
              opacity: 1;
            }
          }
        }
      }
    }

    .side-menu-container {
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      top: 52px;
      right: -100%;
      transition: right 0.5s ease-out;

      &.is-active {
        opacity: 1;
        right: 0;

        .navbar-item {
          opacity: 1;
        }
      }
    }
    .side-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;
      height: 100vh;
      background: black;
      position: absolute;
      right: -350px;
      width: 80%;
      max-width: 350px;
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.17, 0.67, 0.59, 0.87);

      .navbar-item {
        text-align: end;
        width: calc(100% - 30px);
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
        transition: all 0.5s ease-in;
      }

      &.is-active {
        opacity: 1;
        right: 0;

        .navbar-item {
          opacity: 1;
        }
      }
    }
  }

  @media screen and (max-width: 960px) {
    height: 52px;
    background: black;
    .container {
      .navbar-menu {
        padding: 0 10px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
      }
      .navbar-item {
        padding: 10px !important;
        &.app {
          border-left: none !important;
        }
      }
      .navbar-end {
        padding: 0 !important;
      }
      .menu-icon {
        display: block !important;
      }

      .social-icons {
        justify-content: center;
        border-bottom: none !important;
      }
    }
  }
}
