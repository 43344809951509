.FAQ {
  min-height: calc(100vh - 160px);
  position: relative;
  z-index: 2;

  .activated {
    .question {
      color: white;
    }
  }
  .question {
    transition: all 0.3s linear;
    font-size: 1.5rem;
    font-weight: bold;
    color: rgba(200, 200, 200);
  }
  .thanks {
    margin-top: 200px;
    p {
      text-align: center;
      font-size: 3rem;
      margin-top: 40px;
      &.thank {
        font-size: 5rem;
        margin-bottom: 100px;
      }
    }
  }

  .social-networks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    a {
      margin-right: 10px;
      text-decoration: none;
    }
    .social {
      color: white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 25px;
      border: 1px solid white;
      border-radius: 500px;
      img {
        margin-right: 5px;
        height: 18px;
      }
    }
  }
  .still-here {
    margin-top: 3000px;
    text-align: center;
  }

  .MuiAccordion-root {
    background: none;
    border-bottom: 1px solid var(--secondary);

    .MuiButtonBase-root,
    .MuiAccordionDetails-root {
      background: none;
      border-top: none;
    }
      p{
        width: 100%;
        text-align: center;
      }
    
  }

  @media screen and (max-width: 960px) {
    .question {
      font-size: 1.2rem;
    }
    .thanks {
      p {
        font-size: 2.2rem;
        &.thank {
          font-size: 4rem;
        }
      }
    }

    .social-networks {
      justify-content: center;
      .social {
        padding: 10px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .question {
      font-size: 1.1rem !important;
    }
    .thanks {
      p {
        font-size: 2rem !important;
        &.thank {
          font-size: 3.5rem !important;
        }
      }
    }
    .social-networks {
      .social {
        padding: 5px !important;
      }
    }
  }
}
