.YourNFTS {
  margin-top: 80px;
  .wrongNet {
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
  }
}
