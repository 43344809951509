.Traits {
  min-height: calc(100vh - 160px);
  z-index: 2;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;

  &:after{
    bottom: -1px;
    top: unset;
    background: linear-gradient(to bottom, transparent, var(--primary));
  }

  position: relative;
  .traits-background {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    object-fit: cover;
    transform: scale(1.25);
    z-index: -1;
  }
  
  .container {
    z-index: 15;
  }
  .brief {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    .content {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      
      p{
        text-align: center;
        font-size: 1.1rem;
      }

      .rarity-calc-btn {
        align-self: center;
        text-decoration: none;
        padding: 15px 25px;
        color: white;
        font-size: 1.2rem;
        margin-top: 40px !important;
        cursor: pointer;

        &.disabled-link{
          pointer-events: none;
          opacity: 0.3;
        }
      }
    }
    .traits-table {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      img {
        height: 250px;
        object-fit: cover;
        margin-top: -50px;
        transform: rotate(-20deg);
      }
      .table {
        margin-left: 60px;
        flex: 1;
        display: flex;
        flex-direction: column;
        .row {
          display: flex;
          flex-direction: row;
          b {
            font-size: 0.9rem;
            white-space: nowrap;
            flex: 1;
          }
          &:not(:last-of-type) {
            border-bottom: 1px solid white;
          }
          .cell {
            flex: 1;
            padding: 1px 5px;
            text-align: center;
            &:first-of-type {
              text-align: left;
            }
            &:last-of-type {
              text-align: right;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 740px) {
    .content {
      padding: 0 10px;
    }
    .traits-table {
      img {
        display: none;
      }
      .table {
        margin-left: unset !important;
      }
    }
  }
}
