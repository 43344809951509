.NFTs {
  background: #2c2b3c;
  position: relative;

  .spinner-container {
    display: flex;
    justify-content: center;
  }

  .nfts {
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    gap: 30px;
    place-content: center;
   
  }
}
