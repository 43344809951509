.About {
  // margin-top: 60px;
  // padding-bottom: 100px;
  min-height: calc(100vh - 160px);
  z-index: 2;
  position: relative;
  display: flex;

  .confettiContainer {
    position: fixed !important;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.8);
    z-index: 129 !important;
    .confetti {
      z-index: 131;
    }

    .newNftContainer {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
      flex: 1;

      .seeNftText {
        padding: 20px 30px;
        font-size: 3.2rem;
        font-weight: 700;
        color: white;
        text-decoration: none;
        cursor: pointer;

        transform: scale(0);
        opacity: 0;
        transition: all 0.8s cubic-bezier(0.18, 0.89, 0.32, 1.27);

        &.cardAnimation {
          opacity: 1;
          transform: scale(1);
        }
      }
    }

    // .newNftContainer {
    //   width: 100%;
    //   height: 100%;
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   z-index: -1;
    //   flex: 1;
    //   flex-wrap: wrap;

    //   .newNft {
    //     width: 20%;
    //     margin-top: 20px;
    //     margin-right: 10px;
    //     // width: 370px;
    //     // height: 370px;
    //     border-radius: 5px;
    //     overflow: hidden;
    //     // background: red;
    //     transform: scale(0);
    //     opacity: 0;
    //     transition: all 0.8s cubic-bezier(0.18, 0.89, 0.32, 1.27);

    //     &.newNftAnimation {
    //       opacity: 1;
    //       transform: scale(1);
    //     }

    //     img {
    //       width: 100%;
    //       height: 100%;
    //     }
    //   }
    // }
  }

  .container {
    display: flex;
    justify-content: center;
    position: relative;
  }
  .content {
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      margin: 0;
      text-align: center;
      border-bottom: unset;
    }
    p:not(.title) {
      font-size: 1.1rem;
    }
    p.sub-title {
      margin: 2.5rem;
      text-align: center;
      font-size: 1.5rem;
    }

    .minting {
      .check-wallet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        input,
        button {
          text-align: center;
          width: 230px;
          height: 45px;
          border-radius: 10px;
          outline: none;
          border: none;
          font-size: 1.1rem;
        }
        input {
          padding: 0 15px;
        }
        button {
          padding: 0;
          margin-top: 10px !important;
        }
        .disabled {
          opacity: 0.5;
        }
      }
      .bottom {
        display: flex;
        justify-content: space-evenly;
        padding-top: 40px;
      }

      .nfts {
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        padding-right: 30px;

        .nft {
          width: 30%;
          border-radius: 5px;
          overflow: hidden;
          margin-top: 20px;
          margin-right: 10px;
          // height: 300px;
          // background: red;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .placeholder {
        width: fit-content;
        font-size: 3.5rem;
        font-weight: bold;
      }

      .top {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .wallet-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          .progress-span{
            font-size: 1.2rem;
            color: var(--secondary);
            margin-bottom: 20px;
          }
          // box-shadow: 0px 0px 25px rgb(255 255 255 / 50%);
          // margin-top: 20px;
          // width: 100%;
          // border-radius: 10px;
          // background: black;
          // justify-self: flex-end;
          // padding: 25px;
          // text-align: center;
          // button {
          //   padding: 20px 40px;
          // }
          // p {
          //   text-align: center;
          // }
          // // &.false {
          // //   width: 100%;
          // //   text-align: center;
          // // }

          // .progress-span {
          //   font-size: 1.4rem;
          //   span {
          //     border-bottom: 1px solid var(--secondary);
          //     padding-bottom: 3px;
          //   }
          // }

          // .mint-dates {
          //   display: flex;
          //   align-items: center;
          //   margin: 20px 0px;
          //   p {
          //     margin: 0px;
          //   }

          //   .countdown-container {
          //     width: calc(100% - 140px);
          //     .Countdown {
          //       display: flex;
          //       align-items: center;
          //     }
          //     .sale-countdown {
          //       margin-left: 10px;
          //       display: flex;
          //       &:first-of-type {
          //         border-bottom: 1px solid var(--secondary);
          //       }
          //       > p {
          //         display: flex;
          //         align-items: center;
          //         font-size: 2rem;
          //       }
          //     }
          //   }
          // }
          // .progress-bar {
          //   position: relative;

          //   .bar-container {
          //     width: 100%;
          //     height: 25px;
          //     border-radius: 20px;
          //     overflow: hidden;
          //     background: #7a665f;

          //     .progress-span {
          //       position: absolute;
          //       top: 50%;
          //       left: 50%;
          //       transform: translate(-50%, -50%);
          //     }

          //     .bar {
          //       max-width: 100%;
          //       height: 100%;
          //       border-radius: 20px;
          //       background: var(--secondary);
          //       font-size: 0.8rem;
          //       color: white;

          //       span {
          //         position: absolute;
          //         top: 50%;
          //         left: 50%;
          //         transform: translate(-50%, -50%);
          //         color: white;
          //         font-weight: bold;
          //       }
          //     }
          //   }
          // }
          // .price {
          //   margin: 25px 0;
          // }
          // .wallet-info-bottom {
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          // }
          // .connect-wallet {
          //   margin-top: 20px;
          // }
        }

        .button {
          position: relative;
          min-width: 126px;
          padding: 15px 25px;
          color: white;
          font-size: 1.2rem;
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: center;
          &.disable {
            opacity: 0.4;
          }
          .change-count-btn {
            font-size: 2rem;
            margin: 0 8px;
            cursor: pointer;

            &.disable {
              opacity: 0.4;
            }
          }
        }
      }
    }
  }

  .MuiCircularProgress-root {
    width: 12px !important;
    height: 12px !important;
  }

  .wrongNet {
    width: 100vw;
    height: 100vh;
    background: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    top: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: bold;
  }

  @media screen and (max-width: 600px) {
    .minting {
      .bottom {
        flex-direction: column !important;
      }
    }
    .newNft {
      width: 280px;
      height: 280px;
    }
    .placeholder {
      font-size: 1.5rem;
      padding: 0;
    }

    .countdown {
      font-size: 2rem;
    }

    .wallet-info {
      width: 100% !important;
      .wallet-info-bottom {
        flex-direction: column;
        .claim-airdrop {
          margin-top: 10px;
        }
      }
    }
  }
}
.airdrop-modal {
  display: flex;
  justify-content: center;
  align-items: center;

  .TabWiew {
    background: #000000;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    border: 1px solid;
    padding-bottom: 16px;

    p {
      padding: 0 10px;
      &.drop-description {
        margin: 20px 0;
      }
    }
    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      .Tab {
        flex: 1;
        border-bottom: 1px solid;
        text-align: center;
        font-weight: bold;
        padding: 5px 0;

        &.Active {
          background: white;
          span {
            color: black;
          }
        }
        &.Border {
          border-left: 1px solid;
          border-right: 1px solid;
        }
      }
    }
    .buttons {
      padding: 0 10px;
      display: flex;
      justify-content: center;
      button {
        width: 150px;
        outline: none;
      }
    }

    .error-text {
      color: rgb(230, 0, 0);
    }
  }
}
