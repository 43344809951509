.Layout {
  position: relative;
  .NavBar {
    height: 80px;
    border-bottom: 1px solid var(--tertiary);
    align-items: center;
    display: flex;
    flex-direction: column;
    position: relative;
    ul {
      padding: 0;
      display: flex;
      justify-content: center;
      gap: 20px;
      list-style-type: none;
      height: 100%;
      li {
        .logo {
          width: 50px;
        }
        align-self: center;
        cursor: pointer;
        transition: color 0.2s ease;
        &.Active {
          button {
            color: var(--secondary);
          }
        }
        button {
          background: transparent;
          font-size: 1.2rem;
          font-weight: 800;
          &:disabled {
            opacity: 0.4;
          }
        }
      }
    }
    .WalletContainer {
      position: absolute;
      right: 3%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
