.WalletContainer {
  .MuiCircularProgress-root {
    width: 30px !important;
    height: 30px !important;
    margin-right: 3rem;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.2s ease;
  &:hover {
    opacity: 0.8;
  }
}
