$roadmapColor: rgb(18, 20, 32);
.Roadmap {
  min-height: calc(100vh - 160px);
  position: relative;
  z-index: 2;
  .dot-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &:before {
      margin-top: -10px;
      height: 2px;
      width: 60%;
      content: "";
      background: white;
      box-sizing: border-box;
      position: absolute;
    }
    &:after {
      content: "";
      margin-top: -10px;
      width: 40%;
      right: 0;
      border-bottom: 2px dashed white;
      position: absolute;
    }
    // &:after {
    //   transition: all 2s ease-out;
    //   content: "";
    //   width: 2400px;
    //   height: 100%;
    //   position: absolute;
    //   right: -100px;
    //   background: linear-gradient(
    //     270deg,
    //     rgba(18, 20, 32, 1) 0%,
    //     rgba(18, 20, 32, 1) 50%,
    //     rgba(18, 20, 32, 0) 100%
    //   );
    // }
    .point {
      height: 30px;
    }
    .dot {
      position: relative;
      width: 40px;
      height: 40px;
      background: rgba($color: #000000, $alpha: 0.5);
      text-align: center;
      border-radius: 100%;
      border: 2px solid var(--secondary);
      backdrop-filter: blur(5px);
      &.active {
        &:after {
          background: none;
          border: 2px solid white;
        }
        // &:before {
        //   content: "";
        //   width: 50px;
        //   height: 50px;
        //   position: absolute;
        //   top: -10px;
        //   left: -10px;
        //   border-radius: 100%;
        //   border: 1px solid var(--secondary);
        //   box-sizing: border-box;
        //   animation: scale 1.25s ease-out;
        //   animation-iteration-count: infinite;
        //   z-index: -1;
        // }
      }
      .label {
        color: var(--secondary);
        position: absolute;
        top: 45px;
        left: 50%;
        width: 200px;
        transform: translateX(-50%);
      }

      &:after {
        content: "";
        width: 18px;
        height: 18px;
        position: absolute;
        background: white;
        top: 9px;
        left: 9px;
        border-radius: 100%;
        box-sizing: border-box;
      }

      &.future {
        &:after {
          background: none;
          border: 2px solid white;
        }
      }
    }
    .past {
      z-index: 5;
      padding-top: 30px;
      padding-bottom: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .details {
    width: 100%;
    max-width: 1000px;

    .row {
      padding: 10px;
      :first-child {
        color: var(--secondary);
      }
      p {
        text-align: end;
        margin: 5px;
        &:first-of-type {
          font-size: 1.1rem;
          font-weight: bold;
        }
        &:last-of-type {
          font-weight: 300;
          font-size: 1.1rem;
        }
      }
    }
  }

  .roadmap-section {
    margin-top: 50px;
    margin-bottom: 60px;
    display: flex;
    gap: 50px;

    width: 100%;
    .section {
      width: 50%;
      &.right {
        .details {
          p {
            text-align: start;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 100px;
    .details {
      margin: 0px 10px !important;
      width: calc(100% - 20px) !important;
    }
    .roadmap-section {
      flex-direction: column;
      margin: 20px 0;
      width: 100%;
      gap: 0;

      .section {
        width: 100%;
        p {
          text-align: start;
        }
        
        &:after {
          display: none;
        }
      }
    }
  }
}

@keyframes scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}
