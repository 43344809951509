.TwitterInvite {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: white;

  background: radial-gradient(#121420, #1f1013);
  -webkit-overflow-y: hidden;
  -moz-overflow-y: hidden;
  -o-overflow-y: hidden;
  overflow-y: hidden;
  -webkit-animation: fadeIn 1 1s ease-out;
  -moz-animation: fadeIn 1 1s ease-out;
  -o-animation: fadeIn 1 1s ease-out;
  animation: fadeIn 1 1s ease-out;

  .Logo {
    border-radius: 100%;
    height: 200px;
    cursor: pointer;
  }

  h1 {
    font-size: 2.5rem;
    text-align: center;
  }
  p {
    font-size: 1.5rem;
  }

  .FollowUs {
    margin-top: 20px;
    background: #1d9bf0;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      margin-left: 7px;
      color: white;
      text-decoration: none;
    }
  }

  .FollowUs,
  input,
  button {
    width: 230px;
    height: 45px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 1.1rem;
  }
  input {
    padding: 0 15px;
  }
  button {
    margin-top: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;

    .Spinner {
      color: black;
    }
  }

  button:disabled,
  button[disabled] {
    color: #949494;
  }

  .LinkContainer {
    font-size: 1.1rem;
    display: flex;
    color: white;
    height: 60px;
    display: flex;
    align-items: center;
    position: relative;
    .CloseBtn {
      display: none;
    }
    .LinkContent {
      display: flex;
      align-items: center;
      position: relative;
      p {
        margin-right: 15px;
      }
      .Clipboard {
        cursor: pointer;
      }
      :first-child {
        font-weight: bold;
        margin-right: 15px;
        font-size: 1.5rem;
      }
      .Copied {
        position: absolute;
        margin-left: 5px;
        font-size: 0.8rem;
        right: -50px;
      }
      .RefferalLinkText {
        position: absolute;
        margin-top: 170px;
        text-align: center;
      }
    }
  }

  .light {
    position: absolute;
    width: 0px;
    opacity: 0.75;
    background-color: white;
    box-shadow: #e9f1f1 0px 0px 20px 2px;
    opacity: 0;
    top: 100vh;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
  }

  .x4 {
    -webkit-animation: floatUp 4.5s infinite linear;
    -moz-animation: floatUp 4.5s infinite linear;
    -o-animation: floatUp 4.5s infinite linear;
    animation: floatUp 4.5s infinite linear;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
    left: -34%;
  }

  .x5 {
    -webkit-animation: floatUp 8s infinite linear;
    -moz-animation: floatUp 8s infinite linear;
    -o-animation: floatUp 8s infinite linear;
    animation: floatUp 8s infinite linear;
    -webkit-transform: scale(2.2);
    -moz-transform: scale(2.2);
    -o-transform: scale(2.2);
    transform: scale(2.2);
    left: -57%;
  }

  .x6 {
    -webkit-animation: floatUp 3s infinite linear;
    -moz-animation: floatUp 3s infinite linear;
    -o-animation: floatUp 3s infinite linear;
    animation: floatUp 3s infinite linear;
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
    left: -81%;
  }

  .x7 {
    -webkit-animation: floatUp 5.3s infinite linear;
    -moz-animation: floatUp 5.3s infinite linear;
    -o-animation: floatUp 5.3s infinite linear;
    animation: floatUp 5.3s infinite linear;
    -webkit-transform: scale(3.2);
    -moz-transform: scale(3.2);
    -o-transform: scale(3.2);
    transform: scale(3.2);
    left: 37%;
  }

  .x8 {
    -webkit-animation: floatUp 4.7s infinite linear;
    -moz-animation: floatUp 4.7s infinite linear;
    -o-animation: floatUp 4.7s infinite linear;
    animation: floatUp 4.7s infinite linear;
    -webkit-transform: scale(1.7);
    -moz-transform: scale(1.7);
    -o-transform: scale(1.7);
    transform: scale(1.7);
    left: 62%;
  }

  .x9 {
    -webkit-animation: floatUp 4.1s infinite linear;
    -moz-animation: floatUp 4.1s infinite linear;
    -o-animation: floatUp 4.1s infinite linear;
    animation: floatUp 4.1s infinite linear;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    left: 85%;
  }

  @-webkit-keyframes floatUp {
    0% {
      top: 100vh;
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      top: 0vh;
      opacity: 0.8;
    }
    75% {
      opacity: 1;
    }
    100% {
      top: -100vh;
      opacity: 0;
    }
  }
  @-moz-keyframes floatUp {
    0% {
      top: 100vh;
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      top: 0vh;
      opacity: 0.8;
    }
    75% {
      opacity: 1;
    }
    100% {
      top: -100vh;
      opacity: 0;
    }
  }
  @-o-keyframes floatUp {
    0% {
      top: 100vh;
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      top: 0vh;
      opacity: 0.8;
    }
    75% {
      opacity: 1;
    }
    100% {
      top: -100vh;
      opacity: 0;
    }
  }
  @keyframes floatUp {
    0% {
      top: 100vh;
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      top: 0vh;
      opacity: 0.8;
    }
    75% {
      opacity: 1;
    }
    100% {
      top: -100vh;
      opacity: 0;
    }
  }
}

@media screen and (max-width: 600px) {
  .TwitterInvite {
    .Logo {
      height: 130px;
      margin-top: 20px;
    }
    h1 {
      font-size: 1.8rem;
    }
    p {
      font-size: 0.9rem;
    }
    .LinkContainer {
      &.ShowLink {
        position: absolute;
        background: rgba(0, 0, 0, 0.9);
        width: 100vw;
        height: 100vh;

        .CloseBtn {
          display: unset;
          z-index: 11;
        }
      }

      .CloseBtn {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .LinkContent {
        width: 100%;
        height: 100%;
        position: relative;
        flex-direction: column;
        justify-content: center;
        p {
          margin-right: unset;
          text-align: center;
        }
        .Copied {
          margin-left: unset;
          right: unset;
          margin-top: 140px;
        }

        .RefferalLinkText {
          position: unset;
          margin-top: 40px;
        }
      }
    }
  }
}
