.WillBeUsedFor {
  min-height: calc(100vh - 160px);
  z-index: 2;
  position: relative;
  margin-bottom: 100px;

  .whaleIslands {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .wislLogo {
      height: 140px;
    }
    .utility {
      * {
        font-size: 1.1rem;
        margin: 2px 0;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 300px;
      }
      .utilityTitle {
        color: var(--secondary);
        margin-bottom: 15px;
      }
    }
    .video {
      margin: 20px;
      width: 500px;
      position: relative;
      .iframe {
        border-radius: 20px;
        overflow: hidden;
      }
    }
  }
}

@media screen and (min-width: 960px) and (max-width: 1100px) {
  .container {
    .whaleIslands {
      .video {
        width: 580px;
        .iframe {
          width: 580px;
        }
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .container {
    .whaleIslands {
      flex-direction: column-reverse;
      .utility {
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .whaleIslands {
      .video {
        width: 320px;
        .iframe {
          width: 320px;
          height: 200px;
        }
      }
    }
  }
}

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
