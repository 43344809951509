.Utilities {
  min-height: calc(100vh - 160px);
  z-index: 2;
  position: relative;

  .container {
    max-width: 1200px;
  }
  .brief {
    gap: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    .usage {
      width: 32%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 465px;
      }

      .architecture-item {
        font-size: 1rem;
        position: absolute;
        &.brending {
          margin-top: -345px;
          margin-right: 215px;
        }
        &.staking {
          margin-top: -345px;
          margin-left: 200px;
        }
        &.using {
          margin-top: -90px;
          margin-left: 370px;
        }
        &.burning {
          margin-top: -90px;
          margin-right: 380px;
        }
        &.reincarnate {
          margin-top: 25px;
        }
        &.dead {
          margin-top: 190px;
          margin-left: 200px;
        }
        &.governs {
          margin-top: 190px;
          margin-right: 200px;
        }
      }
    }
    .content {
      width: 30%;
      .architectureRow {
        min-height: 86px;
        display: flex;
        align-items: center;
        &.hide {
          display: none;
        }
        img {
          height: 45px;
        }
        > div {
          margin-left: 20px;
          b {
            font-size: 1.1rem;
          }
          p {
            margin: 0;
            width: 100%;
            font-size: 1.1rem;
          }
          .itemTitle {
            color: var(--secondary);
          }
        }
      }

      &.left {
        .architectureRow {
          &.hide {
            display: none;
          }
          flex-direction: row-reverse;
          display: flex;
          > div {
            margin-left: 0;
            margin-right: 20px;
            p {
              text-align: end;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1224px) {
    .brief {
      flex-direction: column;
      .usage {
        width: 50%;
      }
      .content {
        width: 50%;

        &.left {
          .architectureRow {
            flex-direction: row;
            > div {
              margin-left: 20px;
              p {
                text-align: start;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .brief {
      flex-direction: column;
      .content {
        width: 70%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 100px;
    .brief {
      .usage,
      .content {
        width: 100%;
        padding: 0 10px;
      }
      .usage {
        img {
          height: 350px;
        }

        .architecture-item {
          font-size: 1rem;
          position: absolute;
          &.brending {
            margin-top: -285px;
            margin-right: 150px;
          }
          &.staking {
            margin-top: -285px;
            margin-left: 150px;
          }
          &.using {
            margin-top: -60px;
            margin-left: 280px;
          }
          &.burning {
            margin-top: -60px;
            margin-right: 290px;
          }
        }
      }
    }
  }
}
