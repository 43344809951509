:root {
  --primary: #121420;
  --secondary: #f5292a;
  --secondary-light: #f08c67;
  --tertiary: #282c46;
  --tertiary-light: #282c467d;
  --tertiary-background: #2c2b3c;
}

body {
  margin: 0;
  font-family: "Mont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiModal-root {
  display: flex;
  justify-content: center;
  align-items: center;
}

* {
  box-sizing: border-box;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

@keyframes idle {
  0% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(10%);
  }
}

@font-face {
  // font-family: "poppins";
  // src: url("./fonts/Poppins/Poppins-Light.ttf");

  font-family: "Mont";
  src: url("./fonts/Mont/Mont-Regular.ttf");
}

button {
  padding: 20px;
  color: white;
  font-size: 1rem;
  border: 0px;
  cursor: pointer;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}