// *:not(input) {
//   -webkit-user-select: none;
//   -moz-user-select: none;
//   -o-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }

main {
  overflow: hidden;
  &.showMain {
    // &::after {
    //   opacity: 1;
    // }

    .navbar {
      .navbar-start,
      .social-icons {
        * {
          opacity: 1;
          top: 0px;
          transition: all 1s ease;
          @for $i from 1 through 9 {
            &:nth-child(#{$i}) {
              transition-delay: #{$i / 9}s;
            }
          }
        }
        .social-icons {
          transition-delay: 0.77s;
        }
      }
    }

    .mainContent {
      visibility: visible;

      .About {
        .content {
          * {
            opacity: 1;
            transition: opacity 1.5s linear;
            $about-delay: 2;
            @for $i from 1 through 3 {
              $about-delay: $about-delay + 0.2;
              &:nth-child(#{$i}) {
                transition-delay: #{$about-delay}s;
              }
            }
          }
        }
      }
    }

    .arrow-down-icon {
      opacity: 1;
      transition-delay: 3.5s !important;
      transition: opacity 1s;
    }
  }

  &::after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    background: rgba($color: #000000, $alpha: 0.5);
    z-index: 0;
    // opacity: 0;
    // transition: all 2s;
  }

  .mainContent {
    height: calc(100vh - 160px);
    width: 100%;
    position: fixed;
    overflow: scroll;
    top: 80px;
    z-index: 1;
    visibility: hidden;
    transition-delay: 2s;

    .About {
      .content {
        * {
          opacity: 0;
        }
      }
    }
  }

  .navbar {
    .navbar-start {
      * {
        position: relative;
        opacity: 0;
        top: -80px;
      }
    }
  }
}

.arrow-down-icon {
  opacity: 0;
  position: fixed;
  display: flex;
  bottom: 45px;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: arrow-down-animation 1.5s linear infinite;

  &.arrow-up {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  .line {
    width: 50px;
    height: 2px;
    background: #626262;
    position: relative;

    &.line1 {
      transform: rotate(25deg);
      left: 3px;
    }
    &.line2 {
      transform: rotate(-25deg);
      right: 2px;
    }
  }
}

@keyframes sky-animation {
  0% {
    background-position-x: 0px;
  }

  50% {
    background-position-x: 24px;
  }

  100% {
    background-position-x: 0px;
  }
}

@keyframes concept-animation {
  0% {
    background-position-x: 0px;
  }

  50% {
    background-position-x: 14px;
  }

  100% {
    background-position-x: 0px;
  }
}

@keyframes man-animation {
  0% {
    left: 600px;
  }

  50% {
    left: 614px;
  }

  100% {
    left: 600px;
  }
}

@media screen and (max-width: 600px) {
  .mainContent {
    height: calc(100vh - 120px);
  }
}

@keyframes arrow-down-animation {
  0% {
    bottom: 35px;
  }

  50% {
    bottom: 45px;
  }

  100% {
    bottom: 35px;
  }
}
