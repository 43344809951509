.Staking {
  position: relative;
  margin-top: 80px;
  .container {
    padding-top: 0px;
  }
  .Boxes {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .StakeBox {
      flex: 1;
    }
  }
  .ExchangeInfo {
    display: flex;
    background: var(--tertiary-light);
    margin-bottom: 4px;
    padding: 10px 15px;
    justify-content: space-evenly;
    .coin {
      display: flex;
      gap: 5px;
      font-size: 0.9rem;
      > span {
        &:last-of-type {
          color: var(--secondary-light);
          font-weight: 600;
        }
      }
    }
  }
  .headerSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    .StakingInfo {
      display: flex;
      gap: 15px;
      align-items: center;
      flex-wrap: wrap;
      .ClaimButton {
        span {
          background: var(--tertiary-background);
          padding: 5px 20px;
          border-radius: 8px;
          font-weight: 600;
          cursor: pointer;
        }
      }
      > div {
        display: flex;
        gap: 25px;
        background: var(--tertiary-background);
        padding: 10px 15px;
        border-radius: 8px;
        min-width: 250px;
        .Section {
          display: flex;
          flex-direction: column;
          gap: 5px;
          &:last-of-type {
            span {
              font-weight: 600;
              flex: 1;
            }
          }
          span {
            white-space: nowrap;
          }
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .headerSection {
      margin-bottom: 60px;
    }
  }
  @media screen and (max-width: 560px) {
    .headerSection {
      flex-direction: column;
      margin-bottom: 60px;
      .StakingInfo {
        flex-direction: column;
      }
    }
    .Boxes {
      gap: 50px;
      .title {
        transform: translate(10px, -6px) !important;
      }
    }
  }
}
