.Team {
  margin-bottom: 200px;
  position: relative;
  z-index: 2;
  .images {
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    a {
      width: 20%;
      img {
        width: 100%;
      }
    }
  }
  .collections {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 100%;
      max-width: 100px;
    }
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 100px;
    .container {
      .images {
        flex-direction: column;
        a {
          margin-top: 30px;
          width: 35%;
        }
        // flex-wrap: wrap;
        // a {
        //   width: 35%;
        // }
      }
      // .collections {
      //   flex-wrap: wrap;
      //   justify-content: flex-start;
      //   a {
      //     width: 25%;
      //   }
      // }
    }
  }
}
